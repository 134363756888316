import React from "react";
import { graphql, PageProps } from "gatsby";
import { Page } from "../components/Page";
import { BlogEntry } from "../components/BlogEntry";
import SEO from "../components/Seo";
import { Container } from "../components/Container";
import { Header } from "../components/Header";

interface PageContext {
  nodes: string[];
  tag: string;
}

const Template = ({
  data,
  pageContext,
}: PageProps<Queries.TagTemplateQuery, PageContext>) => {
  return (
    <Page>
      <SEO title={`Tag: ${pageContext.tag}`} />
      <Header />
      <Container>
        <h1>{pageContext.tag}</h1>
        {data.allMdx.edges.map((edge) => (
          <BlogEntry key={edge.node.id} edge={edge} />
        ))}
      </Container>
    </Page>
  );
};
export default Template;

export const pageQuery = graphql`
  query TagTemplate($nodes: [String]) {
    allMdx(filter: { id: { in: $nodes } }) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            path
            tags
          }
          slug
          timeToRead
          excerpt
        }
      }
    }
  }
`;
